<template>
  <CRow>
    <CCol col="12" xl="12">

      <CButton variant="outline" type="submit" color="primary" class='list-new-btn'
        @click="$router.push({ path: '/manager/articles/create' })" v-if="$store.getters.isAdmin">
        <CIcon name="cil-check-circle" />New Article
      </CButton>

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Articles</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable hover sorter striped table-filter :items="articles" :fields="fields" :items-per-page="perPage"
              :pagination="$options.paginationProps" index-column>

              <template #actions="{ item, index }">
                <td class="py-2">
                  <CButton color="primary" variant="outline" square size="sm" @click="detailClicked(item, index)">
                    Details
                  </CButton>
                </td>
              </template>

            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>

import ArticleAPI from '/app/src/api/article.js'

export default {
  name: 'Articles',
  data: () => {
    return {
      articleAPI: new ArticleAPI(),
      articles: [],
      fields: [
        { key: 'id' },
        { key: 'title' },
        { key: 'actions' }
      ],
      perPage: 20,
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  created: function () {
    this.getArticles()
  },
  methods: {
    getArticles: function () {
      this.$store.dispatch('loading')
      let self = this
      this.articleAPI.all()
        .then((articles) => {

          this.$store.dispatch('stopLoading')

          console.log(articles)
          self.articles = articles
        })
        .catch((error) => {
          this.$store.dispatch('stopLoading')
          console.log(error)
        })
    },
    getBadge(state) {
      return state === 'correct' ? 'success'
        : state === 'broken' ? 'danger' : 'primary'
    },
    detailClicked(item, index) {
      const itemLink = `/manager/articles/${item.id.toString()}`
      this.$router.push({ path: itemLink })
    }
  }
}
</script>
